.rightSubmenu{
    position: absolute; bottom: 0; right: 0
}
.subMenuImage{
    width:100%;
    height:18VW;
    cursor:pointer;
}

.branch-three{
    position: absolute;
    width: 205px;
    bottom: -30px;
    left: 17px;
    cursor: pointer;

}

.branch-row{
    position: relative;

}

.branch-row > div{
    cursor: pointer;
}
.radio-wrapper:hover{
  cursor: pointer;

}
.radio-button{
margin-right: 10px;
}

.label-style{
  font-size: 18px;
  margin:auto;
  padding: 0 2rem;
}
.question-wrapper{
  font-weight: 500;
  font-size:23px;
  text-align:center;
  padding-right:10px;
}
.cursor-label{
  padding: 0px 18px 0px 18px;
}

.cursor-label:hover{
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.031372549);
  padding: 0px 18px 0px 18px;
  border-radius: 3%;
}
.error-message{
  color: red;
  font-size: 14px;
  // display: flex;
  text-align: center;
  padding-bottom: 10px;
}

