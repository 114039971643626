$font-family-text:"Roboto","Open Sans",sans-serif;

.large-text{
font-family:$font-family-text ;
font-size: 48px;
}
.medium-text{
font-family:$font-family-text ;
font-size: 23px;
}
.small-text{
 font-family: $font-family-text;
 font-size: 18px;
}


