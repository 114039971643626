.numberbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

}

.boxcontainer {
  background-color: lightblue;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.key-button {
  width: 45px;
  height: 45px;
  padding: 1px;
  margin: 5px;
  border-radius: 4px;
  float: left;
  cursor: pointer;
  text-align: center;
  line-height: 41px;
  font-size: 20px !important;
  background-color: #DEF1FA !important;

  &.backspace-key {
    width: 65px;
    height: 45px;
  }

  &:hover {
    box-shadow: 0 0 5px 0 #1e9eda !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.key-button:active {
  color: #fff !important;
  background-color: #1e9eda !important;
}

.active-button {
  color: #fff !important;
  background-color: #1e9eda !important;
}

.ok-button {
  display: flex;
  width: 60px;
  height: 60px;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 4px;
  border-radius: 5px;
  background-color: #1d9ed9;
  cursor: pointer;
  transition: background-color .2s;
  justify-content: center;
  align-items: center;


  color: #fff !important;

  &:hover {
    box-shadow: none!important;
    background-color: #15759f !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.disable-button {
  pointer-events: none;
  color: #fff!important;
  background-color: #d8d8d8!important;

}

.audio-box {
  background-color: #1e9eda !important;
  padding: 10px;
  border-radius: 5px;
}

.setting-color {
  background-color: #e95c0b;
}