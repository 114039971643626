.game-contentWrapper {
    width: 100%;
    height: calc(100vh - 50px);
    position: relative;
   }

.ant-position{
   right: -8%;
}

.ant-one-wrapper {
    height: 7vw;
    min-height: 52px;
    width: 3.5vw;
    min-width: 26px;
    transform: translateY(-4vw);

    img {
        height: 100%;
        width: 100%;
    }
}

.top-container {
    z-index: 1;
    top: 40% !important;
}

.fish-net-wrapper {
    position: relative;
    // height: 81vh;
    height: calc(100vh - 150px);
    width: 80vw;
    margin: auto;
    z-index: 1;

}

.fish-img {
    // width: 200px;
    // height: 100px;
    width: 11vw;
    height: 5vw;
    position: absolute;
    // bottom: 5vh;
    bottom: 10vh;
    left: 0;
    z-index: 2;
    animation : pulse 5s linear;

    &.finish-eating {
        animation: unset;
    }


    &.go-back {
        transform: translateX(200vw);
        transition: transform 1s ease-out;
        animation: unset;
    }
    &.turn-fish{
        transform:rotateY(180deg);
        transition:transform 0.1s ease-out;
        animation: unset;
    }

    &.return-fish-net {
        transform: translateX(-100vw);
        transition: transform 3s ease-out;
        animation: unset;
    }

}

@keyframes pulse {
    from {
        transform : translateY(20px);

    }
    20% {
        transform : translateY(0);
    }
    40% {
        transform : translateY(20px);
    }
    60% {
        transform : translateY(0);
    }
    80% {
        transform : translateY(20px);
    }
    to {
        transform : translateY(0);
    }
}
.net-img {
    // width: 220px;
    // height: 98%;
    width: 16vw;
    height: calc(100vh - 200px);
    position: absolute;
    top: 0;
    right: 0;

    &.start-eating {
        // transform: translateX(calc(-80vw + 220px));
        transform: translateX(calc(-80vw + 16vw));
        transition: transform 7s ease-out;
    }


    &.finish-eating {
        // transform: translateX(calc(-80vw + 220px)) scaleX(-1);
        // transition: transform 0.3s ease-out;
        transform: translateX(calc(-80vw + 16vw)) scaleX(-1);
        transition:  0.3s ease-out;
    }

    &.go-back {
        transform:translateX(200vw) scaleX(-1);
        transition: transform 1s ease-out;
    }

    &.eat-fast {
        transform: translateX(calc(-80vw + 16vw + 1px));
        transition: transform 0.4s ease;
    }

    &.return-fish-net {
        transform: translateX(100vw);
        transition: transform 3s ease-out;
    }
    // &.return-fish{
    //     transform: translateX(calc(80vw - 16vw)) scaleX(1);
    //     transition: transform 0.3s ease-out;
    // }
}

.net-img-front{
    z-index:1;
}

.net-img-back{
    z-index:3;
}