.progress-bar-dashed {
  display: inline;
}

.progress-bar-dashed > svg {
  position: relative !important;
  z-index: 1;
}

#previousbest > svg {
  z-index: 1;
}

#progress > svg {
  z-index: 1;
}

#timer > svg {
  z-index: 1;
  width: auto !important;
  height: auto !important;
}
