.monster-container {
  position: absolute;
  display: inline-block;
  width: 100%;
  bottom: 0px;
  pointer-events: none;
}

#cave {
  width: 64%;
  position: relative;
}

#cave > svg {
  position: relative;
}

.spider-animation-container {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
}

#monster-tongue {
  display: flex;
  position: absolute;
  width: 10%;
  height: 4%;
  left: 10%;
  top: 60%;
  transform-origin: left center;
}

.spider-sprite-container {
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.sprite-sheet-animation {
  position: absolute;
  width: 10%;
  max-width: 190px;
  left: 100%;
  bottom: 30%;
  transform: scale(-1, 1);
}

.hanging-spiders-container {
  display: flex;
  position: absolute;
  top: -1px;
  width: 100%;
  min-height: 0px;
  height: 100%;
  max-height: 300px;
  z-index: 3;
}

.red-hanging-spider-sprite-sheet {
  position: absolute;
  width: 4%;
  left: 92%;
  top: 40%;
}

.green-hanging-spider-sprite-sheet {
  position: absolute;
  width: 4%;
  left: 93.5%;
  top: 35%;
}
