.textinput-wrapper{
    display: flex;
    justify-content:center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
appearance: textfield;
}

.default-textbox{
  border:1px solid black !important;

}


.error-InputClass{
  border:1px dashed red !important;

}
.focus-InputClass{
  border:1px solid blue !important;
}
.form-control:focus{
  border-color: #1D9ED9 !important;
  box-shadow: 0 0 0 0.1rem #1D9ED9 !important;
}
