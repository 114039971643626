.modal-section{
    display:flex;
    flex-direction:column;
    background-color:aqua;
}

.logout-modal{
    width: 250px !important;
}

.btn-logout{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    border-radius: 5px;
    cursor: pointer;
    height: 60px;
    color: white !important;
    font-size: 20px;
    border:0px !important;
    background-color: #1d9ed9;
}


.btn-audio{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white !important;
    font-size: 20px;
    border:0px !important;
    background-color: #1d9ed9;
    user-select: none;
}

.audio-playing {
    background-color:#185f81;
}
.audio-stop {
    background-color:#1d9ed9;
}


