@import '../variables/color.scss';
html, body{
    overflow: hidden;
    overflow-y: hidden;
    height: 100%;
    // zoom: 0.9;
    // -moz-transform: scale(0.9);/* Firefox Property */
    // -moz-transform-origin: 0 0;
    // -o-transform: scale(0.9);/* Opera Property */
    // -o-transform-origin: 0 0;
    // -webkit-transform: scale(0.9);/* Safari Property */
    // -webkit-transform-origin: 0 0;
    // transform: scale(0.9); /* Standard Property */
    // transform-origin: 0 0;  /* Standard Property */
}

.background{
    height: 100vh;
    background: cover;
    width: 100%;
    position: absolute;
    z-index: -1;
}
.background-img{
      height: 100%;
      background: cover;
      width: 100%;
      position: absolute;
      z-index: -1;
      user-select: none;
      top: 0;
}
.welcome-page-img{
    width: 100%;
    height: 100%;
    background: cover;
    position: absolute;
    z-index: -1;
    user-select: none;
    top: 0;
    object-fit: cover;
}

.left-nav-content{
    color: white;
    cursor: pointer;
}
.center-content{
    color: white
}
.right-content{
    color: white
}

#header {
    display: flex;
    padding: 10px  20px;
    align-items: center;
    background-color:#e85a0c;
    font-size: 18px;
    font-weight: 600;
    justify-content: space-between;
}

.semsom-logo{
    height:40px ;
}

.nav-icons{
    font-size: 28px;
    text-decoration: none;
    color: white;
    transition: all .2s ease-in-out !important;
}

.nav-icons:hover{
    color: white;
    transform: scale(1.1) !important;
}

.right-nav-conent{
    display: flex;
    align-items: center;

}

.right-nav-conent > .setting-icon{
    margin: 0 10px;
}

.right-nav-conent > .logout-icon{
    margin-left:20px;
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left:50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.container-column{
    flex-direction: column;
}

.welcome-icon{
    width:120px ;
    height: 120px;
    border-radius: 50%;
    background-color:white ;
    transition: all .2s ease-in-out;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    position: relative;
    margin: 0 auto;
}

.welcome-icon:hover{
    transform: scale(1.1);
}

.welcomehome-icon{
    width:120px ;
    height: 120px;
    border-radius: 50%;
    background-color:white ;
    transition: all .2s ease-in-out;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    position: relative;
    margin: 10px auto;
    // margin-top: 200px;
}

.welcomehome-icon:hover{
    transform: scale(1.1);
}

.arrow-icon{
    position: absolute;
    top: 50%;
    left:50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

#footer{
    position: absolute;
    bottom: 0%;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
}

.footer-logo{
    width: 227px;
    float: right;
    padding-right: 30px;
    height: 62px;
    user-select: none;
}

.footer-text, .footer-text:hover{
    color: #333;
    position: absolute;
    right: 50%;
    text-decoration: underline;
    width: 110px;
    text-align: center;
    bottom: 7px;
    transform: translateX(50%);
    font-size: 24;
    font-size: 23px;
    cursor: pointer;
    user-select: none;
}

.modal-90w {
    width: 80%;
    max-width: none!important;
}


.cross-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $blue;
    color: $white;
    padding: 5px 15px;
    cursor: pointer;
    font-size:25px;
    border-radius: 0 4px !important;
    &:hover{
        background-color: rgb(21, 117, 159);
    }
}

.scroll-container{
    overflow-y: scroll;
    overflow-x: none;
    padding-right:20px;
    max-height: calc(100vh - 175px);
    max-width: calc(100vw - 100px);
}

.scroll-container > h4{
    color: #1d9ed9;
}

.modal-body{
    padding: 1rem 3rem !important;
}

.modal-dialog{
    max-width: calc(100vw - 302px) !important;
}

.welcome-text{
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    text-shadow: 0 2px 4px rgb(0 0 0 / 50%);
    color: #fff;
    margin-bottom: 18px;
}