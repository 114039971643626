@import "../variables/color.scss";

.game-main-container {
  flex-grow: 1;
  background-size: cover !important;
  position: relative;
  min-width: 684px;
  overflow: hidden;
}

.leaf-overlay {
  flex-grow: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  min-width: 684px;
  z-index: 3;
  background-size: cover !important;
  pointer-events: none;
}

.top-progress-bar {
  position: absolute;
  left: 0px;
  right: 0px;
}

.bottom-progress-bar-container {
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 2;
}

@media screen and (max-height: 768px) {
  .bottom-progress-bar-container {
    position: absolute;
    width: 100%;
    bottom: -80px;
    z-index: 2;
  }

  .speed-question-box-container,
  .speed-one-air-question-box-container {
    position: absolute;
    left: calc(50% - 225px);
    top: calc(50% - 70px) !important;
    z-index: 2;
    background-color: #fff;
  }
  .power-question-box-container {
    position: absolute;
    left: calc(50% - 225px);
    top: calc(50% - 100px);
    z-index: 2;
    background-color: #fff;
  }
}

.box-container-question-wrapper {
  position: absolute;
  left: calc(50% - 225px);
  top: calc(50% - 100px);
  z-index: 1;
  background-color: #fff;
}

.bottom-container-keyboard {
  position: relative;
  bottom: 0px;
  left: 0px;
  right: 0px;

  .keypad-container {
    position: relative;
    background-color: #ffffff;
    display: flex;
    flex-direction: row-reverse;
    z-index: 2;
    align-items: center;

    &.multipleChoice {
      justify-content: center;
    }

    .keyboard-button {
      display: flex;
      justify-content: center;
      order: 1;
      flex: 1 0 auto;
      padding: 1.5rem 0;
    }
  }

  .backspace-key {
    text-align: left;
    background-size: auto 40%;
    background-image: url("../../assets/icons/backspace_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }

  .backspace-key-select {
    text-align: left;
    background-size: auto 40%;
    background-image: url("../../assets/icons/backspace_white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #1e9eda !important;
  }

  .ok-button-wrapper {
    padding: 1rem;
  }

  @media (max-width: 745px) {
    .keypad-container {
      flex-direction: column-reverse;
    }

    .keyboard-button {
      border-bottom: 1px solid rgba(30, 158, 218, 0.5);
      padding: 1rem 0;
    }

    .ok-button-wrapper {
      display: flex;
      justify-content: center;
      padding: 1rem 0;
    }
  }
}

.timer-bars {
  position: relative;
  cursor: pointer;
  background-color: red;
}

.input-field {
  font-size: 23px;
  line-height: 36px;
  width: 180px;
  height: 45px;

  form {
    height: 100%;
  }

  .form-control {
    margin-left: 0 !important;
  }

  input {
    width: 100%;
    border: none;
    height: 100%;
    padding: 0 10px;
    text-align: center;
    &:focus {
      border: none;
    }
  }
}
@media screen and (min-height: 850px) {
  .helper-card-img {
    bottom: 0px;
    height: 140px;
    max-height: 140px;
  }
  .helper-card-position {
    transform: translate(-50%, 70%);
  }
}
@media screen and (max-height: 849px) {
  .helper-card-img {
    bottom: 0px;
    height: 100px;
    max-height: 130px;
  }
  .helper-card-position {
    transform: translate(-50%, 100%);
  }
}

.show-helper-card {
  z-index: 2;
  animation: helper-card-keyframe 500ms ease 1 both;
}

.show-helper-card > img {
  scale: 0;
  transform-origin: center bottom;
  animation: helper-card-scale-image-keyframe 500ms ease 1 both;
}

@keyframes helper-card-scale-image-keyframe {
  0% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}

@keyframes helper-card-keyframe {
  0% {
    bottom: -50px;
  }

  100% {
    bottom: 105px;
  }
}

.power-game-background-container {
  width: 100%;
  height: 100%;
  display: block;
  background-repeat: no-repeat;
  background-position: center bottom 100px;
}

.power-game-background {
  position: absolute;
  // width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 205px));
  bottom: 0px;
  user-select: none;
  pointer-events: none;
}

.power-leaf-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.game-over {
  position: absolute;
  left: 0px;
  top: 60px;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.game-over-img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-size: cover !important;
  position: relative;
}
