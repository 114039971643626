.mini-map {
  position: absolute;
  width: 489px;
  height: 362px;
  right: 0px;
  bottom: 0px;
  user-select: none;
  pointer-events: none;
}

.mini-map > .clickable-layer {
  position: absolute;
  width: 245px;
  height: 226px;
  bottom: 30px;
  right: 37px;
  cursor: pointer;
  pointer-events: auto;
}

.mini-map > img {
  position: absolute;
}

@media screen and (max-height: 830px) {
  .mini-map {
    position: absolute;
    width: 380px;
    height: 281px;
    right: 0px;
    bottom: 0px;
    user-select: none;
    pointer-events: none;
  }

  .mini-map > .clickable-layer {
    position: absolute;
    width: 190px;
    height: 174px;
    right: 30px;
    bottom: 25px;
    cursor: pointer;
    pointer-events: auto;
  }
}
