.animate-box-container {
  animation-name: show-box-container-keyframe;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes show-box-container-keyframe {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

.question-container {
  width: 450px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  &.voice,
  &.image,
  &.multiplechoice {
    flex-direction: column;
  }

  .media-question-container {
    display: flex;
    align-items: center;
    overflow: hidden;

    .image-question {
      height: 120px;
    }
  }
}

.form-control:focus {
  border-color: #1d9ed9 !important;
  border: 2px solid #1d9ed9 !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0) inset, 0px 0px 8px rgba(0, 0, 0, 0) !important;
}
.input-field > input {
  font-size: 23px;
  line-height: 36px;
  border: 1px solid black;
  caret-color: #1d9ed9;
}

// .frog-input {
//   margin-bottom: 20px;
// }
.media-container {
  flex-direction: column;
}

.text-container {
  height: 163px !important;
}

.box-wrapper {
  background-color: white;
  padding: 0rem 0rem;
  display: flex;
  position: absolute;
  align-items: center;
  color: black;
  min-height: 350px;
  min-width: 500px;
  z-index: 2;
}
.box-container {
  border: none;
  cursor: pointer;
  user-select: none;
}

.ok-button-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.answer-box-container {
  border: 1px solid black;
  border-radius: 4px;
  padding: 12px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
}

.answer-box-container:hover {
  border: 1px solid #289fd7;
}

@keyframes right-answer {
  0% {
    box-shadow: 0 0 0px 0px #9ac137;
    border: 2px solid #9ac137;
  }
  50% {
    box-shadow: 0 0 3px 3px #9ac137;
    border: 2px solid #9ac137;
  }
  100% {
    box-shadow: 0 0 0px 0px #9ac137;
    border: 2px solid #9ac137;
  }
}

.selected-option {
  border: 1px solid #289fd7;
  -webkit-box-shadow: inset 0px 0px 0px 1px #289fd7;
  -moz-box-shadow: inset 0px 0px 0px 1px #289fd7;
  box-shadow: inset 0px 0px 0px 1px #289fd7;
}

.right-answer-box > input {
  border: none;
  pointer-events: none;
  animation: right-answer 0.55s ease forwards;
}

.option-right-answer {
  animation: right-answer 0.55s ease forwards;
}

.wrong-answer-box > input,
.option-wrong-answer {
  border: 2px dashed #eb595e;
  pointer-events: none;
  animation-name: shake;
  -webkit-animation-name: shake;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-duration: 0.4s;
}

.auto-answer-box > input,
.option-auto-answer-box {
  border: 2px dashed #9ac137;
  &:focus{
    border:2px dashed  #9ac137 !important;
  }
  pointer-events: none;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  16%,
  50%,
  83% {
    transform: translateX(-4px);
  }
  33%,
  66% {
    transform: translateX(4px);
  }
}

.power-year-four-question-box-container {
  .question-container {
    width: 380px;
    height: 170px;
    .media-question-container {
      .image-question {
        height: 94px !important;
      }
    }
  }
}

// Max Screen Height: 724
@media screen and (max-height: 724px) {
  .lilly-pad-position {
    position: absolute;
    width: 450px;
    left: calc(50% - 225px) !important;
    bottom: -50px;
  }
  .frog-shadow-position {
    position: absolute;
    width: 450px;
    left: calc(50% - 204px) !important;
    bottom: -50px;
  }
  .frog {
    position: absolute;
    top: 125px;
    left: -14px;
    width: 450px;
    pointer-events: none;
  }
  .power-year-four-question-box-container {
    top: unset;
    left: calc(50% - 190px) !important;
    bottom: 186px;
  }
  .frog-jump-animation {
    animation-name: frog-jump-keyframe;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  @keyframes frog-jump-keyframe {
    0% {
      bottom: 186px;
    }
    15% {
      bottom: 170px;
    }
    50% {
      bottom: 300px;
    }
    60% {
      bottom: 300px;
    }
    85% {
      bottom: 186px;
    }
    100% {
      bottom: 186px;
    }
  }

  .frog-jump-from-screen-animation {
    animation-name: frog-jump-from-screen-keyframe;
    animation-duration: 1s;
  }
  @keyframes frog-jump-from-screen-keyframe {
    0% {
      bottom: 186px;
    }
    50% {
      bottom: 250px;
    }
    100% {
      bottom: -200px;
      scale: 1.2;
    }
  }
}
// Min Screen Height: 725
@media screen and (min-height: 725px) and (max-height: 849px) {
  .lilly-pad-position {
    position: absolute;
    width: 670px;
    left: calc(50% - 335px) !important;
    bottom: -70px;
  }

  .frog-shadow-position {
    position: absolute;
    width: 670px;
    left: calc(50% - 300px) !important;
    bottom: -70px;
  }
  .frog {
    position: absolute;
    top: 110px;
    width: 670px;
    left: -110px;
    pointer-events: none;
  }
  .power-year-four-question-box-container {
    top: unset;
    left: calc(50% - 190px) !important;
    bottom: 288px;
  }
  .frog-jump-animation {
    animation-name: frog-jump-keyframe;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  @keyframes frog-jump-keyframe {
    0% {
      bottom: 288px;
    }
    15% {
      bottom: 260px;
    }
    50% {
      bottom: 420px;
    }
    60% {
      bottom: 420px;
    }
    85% {
      bottom: 288px;
    }
    100% {
      bottom: 288px;
    }
  }

  .frog-jump-from-screen-animation {
    animation-name: frog-jump-from-screen-keyframe;
    animation-duration: 1s;
  }
  @keyframes frog-jump-from-screen-keyframe {
    0% {
      bottom: 285px;
    }
    50% {
      bottom: 350px;
    }
    100% {
      bottom: -200px;
      scale: 1.2;
    }
  }
}
@media screen and (min-height: 850px) {
  .lilly-pad-position {
    position: absolute;
    width: 670px;
    left: calc(50% - 335px) !important;
    bottom: -70px;
  }

  .frog-shadow-position {
    position: absolute;
    width: 670px;
    left: calc(50% - 300px) !important;
    bottom: -70px;
  }
  .frog {
    position: absolute;
    top: 183px;
    width: 670px;
    left: -48px;
    pointer-events: none;
  }
  .speed-one-air-question-box-container {
    position: absolute;
    left: calc(50% - 225px);
    top: calc(50% - 140px);
    z-index: 2;
    background-color: #fff;
  }
  .power-year-four-question-box-container {
    top: unset;
    left: calc(50% - 250px) !important;
    bottom: 288px;
    .question-container {
      width: 500px !important;
      height: 250px !important;
      .media-question-container {
        .image-question {
          height: 125px !important;
        }
      }
    }
    .input-field {
      width: 200px;
      height: 55px;
    }
  }
  .frog-jump-animation {
    animation-name: frog-jump-keyframe;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  @keyframes frog-jump-keyframe {
    0% {
      bottom: 288px;
    }
    15% {
      bottom: 260px;
    }
    50% {
      bottom: 420px;
    }
    60% {
      bottom: 420px;
    }
    85% {
      bottom: 288px;
    }
    100% {
      bottom: 288px;
    }
  }

  .frog-jump-from-screen-animation {
    animation-name: frog-jump-from-screen-keyframe;
    animation-duration: 1s;
  }
  @keyframes frog-jump-from-screen-keyframe {
    0% {
      bottom: 285px;
    }
    50% {
      bottom: 350px;
    }
    100% {
      bottom: -200px;
      scale: 1.2;
    }
  }
}

.frog-jump-from-screen-shadow-animation {
  animation-name: frog-jump-from-screen-shadow-keyframe;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes frog-jump-from-screen-shadow-keyframe {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.frog-shadow-animation {
  animation-name: frog-shadow-keyframe;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes frog-shadow-keyframe {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.2;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.bird > svg {
  width: unset !important;
  height: unset !important;
}
