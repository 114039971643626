.color{
    height: 100vh;
    background-color: rgba(9,25,38,.75);
    opacity: .6;
}

.loader-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	bottom: 0;
	background: transparentize($color: #000000, $amount: 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	z-index:10;
}

.sp {
	position:absolute;
	width: 80px;
	height: 80px;
	clear: both;
	margin: auto;
}

/* Spinner Circle Rotation */
.sp-circle {
	border: 14px white solid;
	border-top: 14px #e85a0c solid;
	border-radius: 50%;
	-webkit-animation: spCircRot .6s infinite linear;
	animation: spCircRot 2s infinite linear;
	z-index: 5;
}
@-webkit-keyframes spCircRot {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(359deg); }
}
@keyframes spCircRot {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}