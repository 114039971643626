.textinput-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
}

.button-styles {
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 0;
}

.modal-header {
    border-bottom: none !important;
}


.game-content {
    width: 100%;
    height: calc(100vh - 50px);
    position: relative;
    background-color: #FEC87E;
}

.setting-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    z-index: 4;
    background-color: rgba(9,25,38,.75);
    opacity: .6;
}

.background-leaf {
    height: calc(100vh - 260px);
    background: cover;
    width: 70%;
    position: absolute;
    z-index: 0;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -60%);

}

.box-style {
    z-index: 1;
}

.form-control {
    border-radius: 0rem !important;
    margin-left: 10px;
}

.bottom-container {
    position: absolute;
    bottom: 0%;
    background-color: #fff;
    width: 100%;
    padding: 30px;
    display: flex;
    z-index: 0;
}

.test>svg {
    position: absolute;
    margin-top: -85px;

}

.pagination-button {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
}

.underline-group {
    position: absolute;
    top: 12%;
    left: 2vw;
    z-index: 0;
    .ant-wrapper {
        height: 7vw;
        min-height: 52px;
        width: 3.5vw;
        min-width: 26px;
        position: absolute;
        top: -4vw;

        img {
            height: 100%;
            width: 100%;
        }
    }
}

.underline {
    position: relative;
    height: 0.4vw;
    min-height: 3.6px;
    width: 4vw;
    min-width: 26px;
    border-radius: 5px;

    .ant-wrapper {
        height: 7vw;
        min-height: 52px;
        width: 3.5vw;
        min-width: 26px;
        position: absolute;
        top: -4vw;

        img {
            height: 100%;
            width: 100%;
        }
    }

    &.activeLine {
        background-color: red;
    }

    &.whiteLine {
        background-color: #fff;
    }
}

.start-game-icon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: white;

    cursor: pointer;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    margin-top: auto;
    bottom: 0;
    margin-bottom: auto;
    transition: all .2s ease-in-out;

}

.start-game-icon:hover {
    transform: scale(1.1);
}

.test {
    position: relative;
}

.ant-wrapper-big {
    height: 24vw;
    min-height: 52px;
    width: 14.5vw;
    min-width: 26px;
    position: absolute;
    top: 1vw;
    left: 58%;

    img {
        height: 100%;
        width: 100%;
    }
}

.back-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(9,25,38,.75);
    z-index: 3;
    cursor: pointer;
}