.page-container {
  flex-grow: 1;
  background-size: cover !important;
}

.foreground {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.page-child-container {
  height: 100%;
  width: 100%;
}

.page-child-container > *:first-child {
  width: 100%;
  height: 100%;
}

.top-right-character {
  position: absolute;
  top: -25px;
  right: 0px;
  user-select: none;
  pointer-events: none;
}

#block_help_2 > svg {
  pointer-events: auto !important;
}
#block_help > svg {
  pointer-events: auto !important;
}
#block_help_3 > svg {
  pointer-events: auto !important;
}
#block_help_4 > svg {
  pointer-events: auto !important;
}
#block_help_5 > svg {
  pointer-events: auto !important;
}
#block_help_6 > svg {
  pointer-events: auto !important;
}
#block_help_7 > svg {
  pointer-events: auto !important;
}
#block_help_8 > svg {
  pointer-events: auto !important;
}
#block_help_9 > svg {
  pointer-events: auto !important;
}
#block_help_10 > svg {
  pointer-events: auto !important;
}
@media screen and (max-width: 820px), (max-height: 650px) {
  .top-right-character {
    img {
      width: 270px;
    }
  }
}
