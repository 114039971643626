.fish-style{
    height: 15vh;
     width: 15vw;
     animation-name:fishMove;
     animation-duration:4s;
     animation :fishMove 6s linear infinite;

}
@keyframes fishMove {
    100%{transform:translateX(-2950px)}

}