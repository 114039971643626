#fish-net-background, #fish-net-foreground {
    left: 100%;
}

.fish-animation-element {
    position: absolute !important;
    top: 0px;
    width: 15%;
    left: -15%;
}

.fish-animation-element>svg {
    position: relative !important;
}

.fish-appear-animation {
    animation-name: fish-appear-animation-keyframes;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards; 
    animation-fill-mode: forwards;
}


@keyframes fish-appear-animation-keyframes {
    0% {
    left: -15%;
    }
    100% {
        left:16%;
    }
}

.fish-swim-animation {
    left: 15% !important;
    animation-name: fish-swim-animation-keyframes;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes fish-swim-animation-keyframes {
    0% {
        top: 0px;
    }
    50% {
        top: 25px;
    }
    100% {
        top: 0px;
    }
}

.fish-net-appear-animation {
    animation-name: fish-net-appear-animation-keyframes;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards; 
    animation-fill-mode: forwards;
}

@keyframes fish-net-appear-animation-keyframes {
    0% {
    left: 100%;
    }
    100% {
        left: 64%;
    }
}


.move-net-to-fish {
    animation-name:  move-net-to-fish-keyframes;
    animation-duration: var(--fish-catch-animation-duration);
    -webkit-animation-fill-mode: forwards; 
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

@keyframes move-net-to-fish-keyframes {
    from {
        left: var(--move-from);
    }
   to {
        left: 15%;
    }
}

.incorrect-animation {
    animation-name: incorrect-animation-keyframes;
    animation-duration:0.75s;
    -webkit-animation-fill-mode: forwards; 
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

@keyframes incorrect-animation-keyframes{
    from {
        left: var(--move-from);
    }
   to {
        left: 16%;
    }
}



.take-fish-animation {
    animation-delay: 1;
    animation-name: take-fish-animation-keyframes;
    animation-duration:0.75s;
    -webkit-animation-fill-mode: forwards; 
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

@keyframes take-fish-animation-keyframes {
    0% {
    transform: scale(-1, 1);
    left: 15%;
    }
    25% {
    transform: scale(-1, 1);
    left: 15%
    }
   100% {
    transform: scale(-1, 1);
    left: 100%;
   }
}

.correct-fish-animation {
    animation-name: correct-fish-animation-keyframes;
    animation-duration:0.5s;
    -webkit-animation-fill-mode: forwards; 
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

@keyframes correct-fish-animation-keyframes {
    0% {
    transform: scale(-1, 1);
    left: 15%;
    }
   100% {
    transform: scale(-1, 1);
    left: -15%;
   }
}


.correct-fish-net-animation {
    animation-name: correct-fish-net-animation-keyframes;
    animation-duration:0.5s;
    -webkit-animation-fill-mode: forwards; 
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

@keyframes correct-fish-net-animation-keyframes {
    0% {
    transform: scale(-1, 1);
    left: var(--move-from);
    }
   100% {
    transform: scale(-1, 1);
    left: 100%;
   }
}
