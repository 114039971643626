.video-button-container {
    display: flex;
    flex-direction: column;
    .video-container {
        width: 600px;
        height:336px;
        overflow: hidden;
        margin-bottom: 10px;
        iframe{
            background: white;
        }
    }

}

.button-container {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white;
    justify-content: center;
    align-items: center;
}