.bird-container {
  display: inline-block;
  position: absolute;
  top: -1px;
  width: 100%;
  min-height: 0px;
  height: 100%;
  max-height: 300px;
  z-index: 1;
}

.bird-sprite-sheet {
  position: absolute;
  width: 5%;
  left: 10px;
  top: 10px;
}
