#root,
.App,
.fullscreen {
  width: 100%;
  height: 100%;
  user-select: none !important;
}

.fullscreen {
  display: flex;
  flex-direction: column;
}

.outer-part {
  overflow: hidden;
  flex-grow: 1;
}
.monkey-svg {
  position: absolute;
  pointer-events: none;
  right: 0;
  img {
    height: 35vh;
    pointer-events: none;
  }
}

.right-nav {
  display: flex;
  flex-direction: row;
}

.nav-bar-user-name {
  font-weight: bold !important;
}

.brick-items-level > img {
  cursor: pointer;
}

.brick-items {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85%;
  width: 85%;
}

.brick-items-level {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  height: 9vw;
  width: 75%;
}

.wood {
  svg {
    width: 10vw;
    height: 7vh;
  }
  margin-right: 5px;
  cursor: pointer;
}

.dash-image {
  height: 100vh;
  width: 100vw;
}

.speed1 {
  min-height: 15px;
  height: auto !important;
  width: 75%;
  cursor: pointer;
}

.secondRowwrapper {
  width: 75%;
}

.speed2 {
  min-height: 15px;
  height: auto !important;
  width: 12%;
  cursor: pointer;
}

.speed3 {
  min-height: 15px;
  height: auto !important;
  width: 87%;
  cursor: pointer;
}

.speed4 {
  min-height: 15px;
  height: auto !important;
  width: 75%;
  cursor: pointer;
}

.thirdRowwrapper {
  width: 75%;
}

.speed5 {
  min-height: 15px;
  height: auto !important;
  width: 20%;
  cursor: pointer;
}

.speed6 {
  min-height: 15px;
  height: auto !important;
  width: 80%;
  cursor: pointer;
}

.fourthRowwrapper {
  width: 75%;
}

.speed7 {
  min-height: 15px;
  height: auto !important;
  width: 20%;
  cursor: pointer;
}

.speed8 {
  min-height: 15px;
  height: auto !important;
  width: 80%;
  cursor: pointer;
}

.navbar-nav {
  .dropdown-menu {
    position: absolute !important;
  }
}

.mainmenu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-style:hover {
  cursor: pointer;
}
.block-complete > svg {
  left: 0px !important;
}
.block-grayedout > svg {
  left: 0px !important;
}
.block-focus > svg {
  left: 0px !important;
}
.block-wall > svg {
  left: 0px !important;
}
.block-click > svg {
  left: 0px !important;
}

.main-menu-container {
  scale: 0.9;
}

.vine-image {
  svg {
    top: -35px;
    left: -29px;
    z-index: 1;
    scale: 1.04;
  }
}
