
.backgroundfail-img{
    flex-grow: 1;
  background-size: cover !important;
  position: relative;
  min-width: 684px;
}
.feedback-text{
  text-shadow: 0 2px 4px rgb(0 0 0 / 50%);
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}
