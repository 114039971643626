@import "../variables/color.scss";

.navbar-section {
  padding: 10px 10px;
  background-color: $primary-color;
}

.navbar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.game-name-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
  align-items: center;
}

.navbar-setting-content {
  position: absolute;
  top: 60px;
  right: 80px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .fullscreen-icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sound-icon {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom-navbar {
  z-index: 100;
}

.setting-button {
  margin: 20px 0;
}
.voice-setting {
  width: 33rem !important;
}
.sound-setting-wrapper {
  margin-top: 30px;
  padding: 10px 0;
  background-color: #ddf1fa;
}
.ok-setting-wrapper {
  padding: 10px 0;
}

.dropdown-content {
  background-color: rgb(255, 255, 255);
  color: white;
  padding: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  top: 60px;
  right: 80px;
  display: none;

  &.show-growth-dropdown {
    display: flex;
    z-index: 1000;
  }
}

.navbar-nav {
  display: flex;
  justify-content: center;
}
.navbar-content-position {
  position: absolute;
  width: 100px;
  left: 50%;
  transform: translate(-50%);
}
.navbar-container {
  display: flex;
  justify-content: space-between !important;
  z-index: 50;
  min-height: 60px;
}

.dropdown::after {
  display: none !important;
}

.svg-graphic > svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  pointer-events: none;
}

.progress-bar-background > svg {
  position: absolute;
  width: unset;
  height: unset;
  top: 0px;
  left: 0px;
  pointer-events: none;
}

.bird-flats > svg {
  width: unset !important;
  height: unset !important;
  z-index: 1;
}
.bird-flats-progress > svg {
  width: unset !important;
  height: unset !important;
  z-index: 1;
}
.test {
  background-color: none;
  border-color: none;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu[data-bs-popper] {
  left: -140px !important;
}

.dropdown-divider {
  height: 50px !important;
  width: 2px;
}
.previous-svg > svg {
  position: absolute !important;
  top: 0;
  background: transparent !important;
  width: 100%;
  height: 100%;
  left: 0;
}

.menuIcon {
  border: none;
  background-color: none;
  display: flex;
  align-items: center;
  color: azure;
  user-select: none;
  -webkit-user-drag: none;

  transition: all 0.2s ease-in-out !important;

  &.setting-icon-menu {
    margin-right: 26px;
    margin-left: 4px;
  }
}

.menuIcon > img:hover {
  transform: scale(1.1) !important;
}

.background-ant-leaf {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.user-events-select {
  user-select: none;
}
.line-border {
  border-right: 1px solid rgba(121, 121, 121, 0.5);
}
@media (orientation: portrait) {
  .student-username-short {
    display: block;
  }
}
@media (orientation: landscape) {
  .student-username-short {
    display: none !important;
  }
}
@media (orientation: portrait) {
  .student-username {
    display: none !important;
  }
}
@media (orientation: landscape) {
  .student-username {
    display: block;
  }
}
.user-name {
  align-items: center;
  display: flex;
  font-family: "Roboto", "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-overflow: ellipsis;
  color: #fff !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
