.background-sublevel2{
    position:relative;
}
.top-leaf-two{
    position: absolute;
    top:9%
}
.leaf-sublevel21{
   position: relative;
    
}
.bottom-leaf-div{
    position: absolute;
    top:75%;
}
.leaf-sublevel22{
    position:relative;
 }

